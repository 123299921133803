import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
	GET_ALL_USERS,
	GET_USER_DEPARTMENT,
	GET_USERS_BY_DEPT_ID,
} from "../TeamManagement/query";
import React, { useEffect, useState } from "react";

const TeamMembersSelect = ({
	handleChange,
	restrictInboxes,
	validInboxes,
	setValidInboxes,
	rootStore,
	departmentId,
	inboxes,
	members,
	setMembers,
	...rest
}) => {
	const inboxStore = rootStore.getInboxStore();

	const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
	const [teamMemberOptions, setTeamMemberOptions] = useState([]);

	const [getUserDepartment] = useLazyQuery(GET_USER_DEPARTMENT, {
		fetchPolicy: "network-only",
	});
	const [getUsersByDept] = useLazyQuery(GET_USERS_BY_DEPT_ID);

	const { data, loading, error } = useQuery(GET_ALL_USERS);

	useEffect(async () => {
		let result = await getUserDepartment({
			variables: {
				departmentId: departmentId,
			},
		});
		let optionsTemp = [];

		result.data.msgbox_UserDepartment.map((user) => {
			optionsTemp.push({
				label: user.User.FirstName + " " + user.User.LastName,
				value: user.User.UserId,
			});
		});

		setMembers(optionsTemp);
	}, []);

	useEffect(async () => {
		if (inboxes && data) {
			let optionsTemp = [];

			if (inboxes.length > 0) {
				let inbTemp = inboxes.map((inbox) => {
					return inbox.value;
				});
				console.log(inbTemp, "< inbTemp");
				let result = await getUsersByDept({
					variables: { inboxIds: inbTemp },
				});
				console.log(result, "< res");
				result.data.msgbox_User.map((user) => {
					optionsTemp.push({
						label: user.FirstName + " " + user.LastName,
						value: user.UserId,
					});
				});
				setTeamMemberOptions(optionsTemp);

				// let optionsTemp = [];
				// if (inboxes.length > 0) {
				// // Filter the users list for the inboxes you have
				// console.log(inboxes, "< inboxes")
				// let options = data.msgbox_User.filter((user) => {
				// 	console.log(user, "< user");
				// 	// Check if any UserDepartment matches an inbox
				// 	return user.UserDepartments.some((dept) =>
				// 	inboxes.some((inbox) => dept?.Department?.DepartmentId === inbox.value)
				// 	);
				// });
				// optionsTemp = options; // Assign to optionsTemp if needed
				// console.log(optionsTemp)
				// }
			} else {
				//set the user options to everyone
				data.msgbox_User.map((user) => {
					optionsTemp.push({
						label: user.FirstName + " " + user.LastName,
						value: user.UserId,
					});
				});
				setTeamMemberOptions(optionsTemp);
			}
		}
	}, [inboxes, data]);

	//get all users - IF there's no inboxes associated with this team

	//otherwise, only get the users who have access to the inboxes for this team

	// who is in the team already

	//push them to the array or whatever this is

	// const { data, loading, error } = useQuery(GET_ALL_INBOXES);
	// const [getInboxTeamCount] = useLazyQuery(GET_INBOX_TEAM_COUNT);

	// useEffect(() => {
	// 	if (restrictInboxes === true && data) {
	// 		parseValidInboxes();
	// 	}
	// }, [restrictInboxes, data]);

	// async function parseValidInboxes() {
	// 	for (const inbox of data.msgbox_Inbox) {
	// 		const maxTeams = inboxStore.getMaxNumberOfTeams(inbox.InboxId);
	// 		//const maxTeams = 8
	// 		let initialCount = await getInboxTeamCount({
	// 			variables: {
	// 				inboxId: inbox.InboxId,
	// 			},
	// 		});
	// 		let notDeleted = initialCount.data.msgbox_DepartmentInbox.filter(
	// 			(dep) => {
	// 				return dep.Department;
	// 			}
	// 		);
	// 		if (notDeleted.length < maxTeams) {
	// 			setValidInboxes((prev) => [...prev, inbox]);
	// 		}
	// 	}
	// }

	// if (error) return null;
	// if (loading) return null;

	return (
		<MsgBoxSelect
			options={teamMemberOptions.map((user) => ({
				label: user.label,
				value: user.value,
			}))}
			isMulti
			value={members}
			onChange={handleChange}
			{...rest}
		/>
	);
};

export default TeamMembersSelect;
