import { Formik } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import { useToasts } from "react-toast-notifications";
import {
	GET_DEPARTMENTS,
	EDIT_TEAM,
	ADD_INBOXES,
	DELETE_INBOXES,
	GET_INBOX_TEAM_COUNT,
	GET_RESELLER_NAME,
	DELETE_ALL_TEAM_MEMBERS,
	ADD_TEAM_MEMBER,
} from "./query";
import { useMutation, useLazyQuery } from "@apollo/client";
import { departmentValidationSchema } from "./validation-schema";
import { useState, useEffect } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";
import TeamMembersSelect from "../TeamMembersSelect/team-members-select.component";

const EditTeam = ({ isOpen, close, selectedTeam, rootStore }) => {
	const inboxStore = rootStore.getInboxStore();
	const [editTeam, { data, loading, error }] = useMutation(EDIT_TEAM);
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);
	const [inboxes, setInboxes] = useState([]);
	const [addInboxes] = useMutation(ADD_INBOXES);
	const [deleteInboxes] = useMutation(DELETE_INBOXES);

	const [deleteAllTeamMembers] = useMutation(DELETE_ALL_TEAM_MEMBERS);
	const [addTeamMember] = useMutation(ADD_TEAM_MEMBER);

	const [members, setMembers] = useState([]);

	const orgId = localStorage.getItem("org_id");
	const [resellerName, setResellerName] = useState("");
	const [validInboxes, setValidInboxes] = useState([]);
	const [err, setErr] = useState("");
	const [getInboxTeamCount] = useLazyQuery(GET_INBOX_TEAM_COUNT);
	const [getResellerName] = useLazyQuery(GET_RESELLER_NAME);
	const [disableSubmit, setDisableSubmit] = useState(false);

	useEffect(() => {
		checkValidInboxes();
	}, [validInboxes, inboxes]);

	useEffect(() => {
		fetchResellerName();
	}, [orgId]);

	const fetchResellerName = async () => {
		let result = await getResellerName({
			variables: {
				orgId: orgId,
			},
		});
		setResellerName(result.data.msgbox_Organisation[0].Reseller.Name);
	};

	function checkValidInboxes() {
		if (validInboxes.length === 0) {
			setDisableSubmit(true);
			setErr(
				`Your current plan doesn't permit additional teams for any inboxes. To upgrade please contact ${resellerName}.`
			);
		} else {
			const validInboxIds = new Set(
				validInboxes.map((validInbox) => validInbox.InboxId)
			);
			const invalidInboxes = inboxes.filter(
				(selectedInbox) => !validInboxIds.has(selectedInbox.value)
			);
			const areAllInboxesValid = invalidInboxes.length === 0;
			if (areAllInboxesValid === false) {
				let listStr = "";
				invalidInboxes.map((invalidInbox) => {
					listStr = listStr + invalidInbox.label + ", ";
				});
				listStr = listStr.replace(/, ([^,]*)$/, ". $1");
				setDisableSubmit(true);
				let str =
					"Your current plan doesn't permit any additional teams for " +
					listStr +
					" Remove to continue.  To upgrade please contact " +
					resellerName +
					".";
				setErr(str);
			} else {
				setDisableSubmit(false);
				setErr("");
			}
		}
	}

	useEffect(() => {
		if (!selectedTeam?.[0]?.inboxes) return setInboxes([]);
		let arr = selectedTeam?.[0]?.inboxes
			.filter((item) => item.Inbox)
			.map((i) => {
				return {
					value: i.Inbox.InboxId,
					label: i.Inbox.Name,
				};
			});
		setInboxes(arr);
	}, [isOpen]);

	const submit = async (values) => {
		console.log(members, "<members");
		let temp = await editTeam({
			variables: {
				name: values.teamName,
				departmentId: selectedTeam[0].id,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Department(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Department,
								query: GET_DEPARTMENTS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});

		await deleteInboxes({
			variables: {
				department: selectedTeam?.[0].id,
			},
		});

		await deleteAllTeamMembers({
			variables: {
				departmentId: selectedTeam?.[0].id,
			},
		});

		for (let i = 0; i < inboxes.length; i++) {
			let obj = {
				inbox: inboxes[i].value,
				department: selectedTeam?.[0].id,
			};
			await addInboxes({
				variables: obj,
			});
		}

		for (let i = 0; i < members.length; i++) {
			let obj = {
				departmentId: selectedTeam?.[0].id,
				userId: members[i].value,
				orgId: orgId,
			};
			await addTeamMember({
				variables: obj,
			});
		}

		inboxStore.reloadPlans();
		addToast("Successfully edited team", {
			appearance: "success",
			autoDismiss: true,
		});
		closeModal();
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	return (
		<MsgBoxModal
			isOpen={isOpen}
			cancel={closeModal}
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			header="Edit A Team"
			description="Use the form below to edit a team"
			onRequestClose={closeModal}
			submitId="edit-team"
			submit={submit}
			submitting={loading}
			submitButtonDisabled={dirty === false || disableSubmit === true}
		>
			<Formik
				initialValues={{
					teamName: selectedTeam[0]?.name,
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={departmentValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					touched,
					validateForm,
					handleChange,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit} id="edit-team">
						<Input
							placeholder="Team Name"
							required
							name="teamName"
							label="Team Name"
							onChange={(e) => {
								handleChange(e);
								setDirty(true);
							}}
							error={errors.teamName}
							value={values.teamName}
						/>
						<UserInboxSelect
							label="Inboxes"
							name="Inboxes"
							onChange={(e) => {
								setInboxes(e);
								setDirty(true);
							}}
							value={inboxes}
							restrictInboxes={true}
							validInboxes={validInboxes}
							setValidInboxes={setValidInboxes}
							rootStore={rootStore}
						/>
						<TeamMembersSelect
							label="Members"
							name="Members"
							onChange={(e) => {
								setMembers(e);
								setDirty(true);
							}}
							members={members}
							setMembers={setMembers}
							// value={members}
							rootStore={rootStore}
							departmentId={selectedTeam[0].id}
							inboxes={inboxes}
						/>
					</form>
				)}
			</Formik>
			{err === "" ? null : (
				<div style={{ color: "red", marginTop: "5px" }}>{err}</div>
			)}
		</MsgBoxModal>
	);
};

export default EditTeam;
