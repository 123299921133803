import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ALL_INBOXES, GET_INBOX_TEAM_COUNT } from "./query";
import React, { useEffect } from "react";

const UserInboxSelect = ({
	handleChange,
	restrictInboxes,
	validInboxes,
	setValidInboxes,
	rootStore,
	...rest
}) => {
	const inboxStore = rootStore.getInboxStore();
	const { data, loading, error } = useQuery(GET_ALL_INBOXES);
	const [getInboxTeamCount] = useLazyQuery(GET_INBOX_TEAM_COUNT, {
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (restrictInboxes === true && data) {
			parseValidInboxes();
		}
	}, [restrictInboxes, data]);

	async function parseValidInboxes() {
		setValidInboxes([])
		for (const inbox of data.msgbox_Inbox) {
			const maxTeams = inboxStore.getMaxNumberOfTeams(inbox.InboxId);
			//const maxTeams = 8
			let initialCount = await getInboxTeamCount({
				variables: {
					inboxId: inbox.InboxId,
				},
			});
			let notDeleted = initialCount.data.msgbox_DepartmentInbox.filter(
				(dep) => {
					return dep.Department;
				}
			);
			if (notDeleted.length < maxTeams) {
				setValidInboxes((prev) => [...prev, inbox]);
			}
		}
	}

	if (error) return null;
	if (loading) return null;

	return (
		<MsgBoxSelect
			options={data.msgbox_Inbox.map((inbox) => ({
				label: inbox.Name,
				value: inbox.InboxId,
			}))}
			isMulti
			onChange={handleChange}
			{...rest}
		/>
	);
};

export default UserInboxSelect;
