import { Formik } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import { useToasts } from "react-toast-notifications";
import { ADD_INBOXES, ADD_TEAM, GET_DEPARTMENTS, GET_INBOX_TEAM_COUNT, GET_RESELLER_NAME } from "./query";
import { useMutation, useLazyQuery } from "@apollo/client";
import { departmentValidationSchema } from "./validation-schema";
import { useState, useEffect, useContext } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";

const AddTeam = ({ isOpen, close, rootStore }) => {
	const inboxStore = rootStore.getInboxStore();
	const [addTeam, { data, loading, error }] = useMutation(ADD_TEAM);
	const [addInboxes] = useMutation(ADD_INBOXES)
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);
	const [inboxes, setInboxes] = useState([])


	const orgId = localStorage.getItem("org_id")
	const [resellerName, setResellerName] = useState("your reseller")
	const [validInboxes, setValidInboxes] = useState([])
	const [err, setErr] = useState("")
	const [getInboxTeamCount] = useLazyQuery(GET_INBOX_TEAM_COUNT)
	const [getResellerName] = useLazyQuery(GET_RESELLER_NAME)
	const [disableSubmit, setDisableSubmit] = useState(false)

	useEffect(() => {
		setInboxes([])
	}, [isOpen])

	useEffect(()=>{
		fetchResellerName()
	},[orgId]);



	useEffect(()=>{
		checkValidInboxes()
	},[validInboxes, inboxes]);


	const fetchResellerName = async () =>{
		let result = await getResellerName({
			variables: {
				orgId: orgId
			}
		})
		setResellerName(result.data.msgbox_Organisation[0].Reseller.Name)
	}


	function checkValidInboxes(){

		if(validInboxes.length===0){
			setErr(`Your current plan doesn't permit additional teams for any inboxes. To upgrade please contact ${resellerName}.`)
			if(inboxes.length > 0){
				setDisableSubmit(true)
			}
			else{
				setDisableSubmit(false)
			}
		}
		else{
			const validInboxIds = new Set(validInboxes.map(validInbox => validInbox.InboxId));
			const invalidInboxes = inboxes.filter(selectedInbox => !validInboxIds.has(selectedInbox.value));
			const areAllInboxesValid = invalidInboxes.length === 0;
			if(areAllInboxesValid===false){
				let listStr = ""
				invalidInboxes.map((invalidInbox)=>{
					listStr = listStr+ invalidInbox.label + ", "
				})
				listStr = listStr.replace(/, ([^,]*)$/, ". $1");
				setDisableSubmit(true)
				let str = "Your current plan doesn't permit any additional teams for "+
						listStr	+" Remove to continue.  To upgrade please contact " +resellerName+"."
				setErr(str)
			}
			else{
				setDisableSubmit(false)
				setErr("")
			}
		}
	}

	const submit = async ({ teamName }) => {
		let temp = await addTeam({
			variables: {
				teamName,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Department(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.insert_msgbox_Department,
								query: GET_DEPARTMENTS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		for(let i = 0; i < inboxes.length; i++){
			let obj =  {
				inbox: inboxes[i].value,
				department: temp.data.insert_msgbox_Department_one.DepartmentId,
			}
			await addInboxes({
				variables: obj
			})
		}
		inboxStore.reloadPlans();
		addToast("Successfully created team", {
			appearance: "success",
			autoDismiss: true,
		});
		closeModal();
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	return (
		<MsgBoxModal
			isOpen={isOpen}
			cancel={closeModal}
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			header="Add A Team"
			description="Use the form below to add a team"
			onRequestClose={closeModal}
			submitId="add-team"
			submit={submit}
			submitting={loading}
			submitButtonDisabled={dirty === false || disableSubmit===true}
		>
			<Formik
				initialValues={{
					teamName: ""
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={departmentValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					touched,
					validateForm,
					handleChange,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit} id="add-team">
						<Input
							placeholder="Team Name"
							required
							name="teamName"
							label="Team Name"
							onChange={(event) => {
								handleChange(event);
								setDirty(true);
							}}
							error={errors.teamName}
							value={values.teamName}
						/>
						<UserInboxSelect
							label="Inboxes"
							name="Inboxes"
							onChange={(e) => {
								setInboxes(e)
								setDirty(true);
							}}
							value={inboxes}
							restrictInboxes={true}
							validInboxes={validInboxes}
							setValidInboxes={setValidInboxes}
							rootStore={rootStore}
						/>
					</form>
				)}
			</Formik>
			{err==="" ? (null) : (<div style={{color:"red", marginTop: "5px"}}>{err}</div>) }
		</MsgBoxModal>
	);
};

export default AddTeam;
