import { gql } from "@apollo/client";

export const GET_DEPARTMENTS = gql`
	query getDepartments(
		$limit: Int
		$offset: Int
		$order_by: msgbox_Department_order_by!
		$searchTerm: String
	) {
		msgbox_Department(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
			where: { Name: { _ilike: $searchTerm } }
		) {
			Name
			DepartmentId
			UserDepartments {
				User {
					FirstName
					EMail
					UserId
					LastName
				}
			}
			DepartmentInboxes {
				DepartmentId
				InboxId
				Inbox {
					Name
					InboxId
				}
			}
		}
		msgbox_Department_aggregate {
			aggregate {
				count
			}
		}
	}
`;

export const GET_USERS = gql`
	query getUsers($searchTerm: String) {
		msgbox_User(
			where: {
				IsBot: { _eq: false }
				_or: [
					{ FirstName: { _ilike: $searchTerm } }
					{ LastName: { _ilike: $searchTerm } }
				]
			}
		) {
			FirstName
			LastName
			UserId
		}
	}
`;

export const ADD_TEAM = gql`
	mutation ($teamName: String!) {
		insert_msgbox_Department_one(object: { Name: $teamName }) {
			Name
			DepartmentId
		}
	}
`;

export const DELETE_TEAM = gql`
	mutation deleteTeam($departmentId: Int!) {
		update_msgbox_Department(
			where: { DepartmentId: { _eq: $departmentId } }
			_set: { IsDeleted: true }
		) {
			affected_rows
			returning {
				Name
				DepartmentId
			}
		}
	}
`;

export const EDIT_TEAM = gql`
	mutation editTeam($departmentId: Int!, $name: String) {
		update_msgbox_Department(
			_set: { Name: $name }
			where: { DepartmentId: { _eq: $departmentId } }
		) {
			affected_rows
			returning {
				DepartmentId
				Name
			}
		}
	}
`;

export const ADD_INBOXES = gql`
	mutation addInboxes($inbox: Int, $department: Int) {
		insert_msgbox_DepartmentInbox_one(
			object: { InboxId: $inbox, DepartmentId: $department }
		) {
			DepartmentId
		}
	}
`;

export const DELETE_INBOXES = gql`
	mutation addInboxes($department: Int) {
		delete_msgbox_DepartmentInbox(
			where: { DepartmentId: { _eq: $department } }
		) {
			affected_rows
		}
	}
`;

export const GET_INBOX_TEAM_COUNT = gql`
	query MyQuery($inboxId: Int!) {
		msgbox_DepartmentInbox(where: { InboxId: { _eq: $inboxId } }) {
			Department {
				Name
				DepartmentId
			}
		}
	}
`;

export const GET_RESELLER_NAME = gql`
	query MyQuery($orgId: Int!) {
		msgbox_Organisation(where: { OrganisationId: { _eq: $orgId } }) {
			Reseller {
				Brand
				Name
			}
		}
	}
`;

export const GET_USER_DEPARTMENT = gql`
	query MyQuery($departmentId: Int!) {
		msgbox_UserDepartment(where: { DepartmentId: { _eq: $departmentId } }) {
			User {
				FirstName
				LastName
				UserId
			}
		}
	}
`;

export const GET_ALL_USERS = gql`
	query MyQuery {
		msgbox_User {
			FirstName
			LastName
			UserId
			UserDepartments {
				Department {
					DepartmentId
					Name
				}
			}
		}
	}
`;

export const GET_USERS_BY_DEPT_ID = gql`
	query MyQuery($inboxIds: [Int!]) {
		msgbox_User(
			where: {
				IsDeleted: { _eq: false }
				UserInboxes: { InboxId: { _in: $inboxIds } }
			}
		) {
			FirstName
			LastName
			UserId
			UserDepartments {
				Department {
					DepartmentId
					Name
				}
			}
		}
	}
`;

export const DELETE_ALL_TEAM_MEMBERS = gql`
	mutation MyMutation($departmentId: Int!) {
		delete_msgbox_UserDepartment(
			where: { DepartmentId: { _eq: $departmentId } }
		) {
			affected_rows
			returning {
				UserId
				DepartmentId
			}
		}
	}
`;

export const ADD_TEAM_MEMBER = gql`
	mutation MyMutation($departmentId: Int!, $userId: Int!) {
		insert_msgbox_UserDepartment(
			objects: { DepartmentId: $departmentId, UserId: $userId }
		) {
			affected_rows
			returning {
				DepartmentId
				UserId
			}
		}
	}
`;
