import { makeAutoObservable, runInAction, reaction } from "mobx"
import { LOAD_INBOXES } from "./inboxQueries"
import client from "../Apollo";
import { RootStore } from "./rootStore";

export class InboxStore {
    rootStore
    orgId: number = 0
    userId: number = 0
    inboxes: Inbox[] = []
    apiAccounts: APIAccount[] = []
    folders: Folder[] = []
    isLoading = true

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    // needs to be called whenever we add/remove users from the organisation to ensure we are alwasy on the correct plan.
    async reloadPlans() {
        await this.init(this.orgId, this.userId);
    }

    //init it called once when the msgboxx web app loads.
    async init(orgId: number, userId: number) {
        //console.log("loading stuff")
        this.isLoading = true
        this.orgId = orgId
        this.userId = userId
        const response = await client.query({
            query: LOAD_INBOXES,
            variables: {
                userId,
            },
            fetchPolicy: "network-only",
        });
        runInAction(() => {
            if (response && response.data) {
                response.data.msgbox_UserInbox.forEach((inbox: any) => {
                    var currentUserCount = inbox.Inbox.UserInboxes.length
                    var relevantPlan = {AllowedTeams: 1000, AllowedUsers: 1000, AllowedChatFlows: 1000, AllowedTemplates: 1000,  AllowedBroadcasts: 1000}
                    if (inbox.Inbox.Plan.PlanVariants.length > 0) {
                        relevantPlan = inbox.Inbox.Plan.PlanVariants.find((z: any) => z.AllowedUsers > currentUserCount)
                        if (!relevantPlan) {
                            // we need the last one?
                            relevantPlan =  inbox.Inbox.Plan.PlanVariants[inbox.Inbox.Plan.PlanVariants.length - 1]
                        }
                    } 
                    console.log("relevant plan:", inbox.Inbox.Name, relevantPlan)
                    var apiAccount = { apiAccountId: inbox?.Inbox?.APIAccount?.APIAccountId, name: inbox?.Inbox?.APIAccount?.Name, apiAccountUUID: inbox?.Inbox?.APIAccount?.APIAccountUUID }
                    this.apiAccounts.push(apiAccount)
                    var inboxFolders: Folder[] = []
                    if (inbox && inbox.Inbox && inbox.Inbox.Folders) {
                        inbox.Inbox.Folders.forEach((folder: any) => {
                            inboxFolders.push({ folderId: folder.FolderId, name: folder.Name, isInbox: folder.IsInbox, isArchive: folder.IsArchive, isSpam: folder.IsSpam, inboxId: inbox.InboxId })
                            this.folders.push({ folderId: folder.FolderId, name: folder.Name, isInbox: folder.IsInbox, isArchive: folder.IsArchive, isSpam: folder.IsSpam, inboxId: inbox.InboxId })
                        })    
                        var myInbox = { inboxId: inbox.InboxId, name: inbox.Inbox.Name, apiAccount: apiAccount, apiAccountId: inbox?.Inbox?.APIAccount?.APIAccountId, folders: inboxFolders, 
                            planName: inbox?.Inbox?.Plan?.Name, isBulkImportContactAllowed: inbox?.Inbox?.Plan?.IsBulkImportContactAllowed, isAnalyticsAccessAllowed: inbox?.Inbox?.Plan?.IsAnalyticsAccessAllowed, isAIReplyAllowed: inbox?.Inbox?.Plan?.IsAIReplyAllowed,
                            maxTeams: relevantPlan.AllowedTeams, maxUsers: relevantPlan.AllowedUsers, maxChatFlows: relevantPlan.AllowedChatFlows, maxTemplates: relevantPlan.AllowedTemplates, maxBroadcastSize: relevantPlan.AllowedBroadcasts
                        }
                        this.inboxes.push(myInbox)
                    }
                })
            }
        })
        this.isLoading = false
        //console.log("finished loading stuff")
        //console.log("inboxes:", this.inboxes)
        //console.log("apiAccounts:", this.apiAccounts)
    }

    getAllInboxFolderIds() {
        return this.folders.filter((x) => x.isInbox === true).map((x) => x.folderId)
    }

    getInboxCount() {
        return this.inboxes.length;
    }

    getFolder(id: number) {
        const folder = this.folders.filter(x => x.folderId === id)
        if (folder && folder[0]) {
            return folder[0]
        }
        return null
    }
    getAllApiAccounts() {
        return this.apiAccounts;
    }
    getApiAccount(id: number) {
        const apiAccount = this.apiAccounts.filter(x => x.apiAccountId === id)
        if (apiAccount && apiAccount[0]) {
            return apiAccount[0]
        }
        return null
    }
    getInbox(id: number) {
        const inbox = this.inboxes.filter(x => x.inboxId === id)
        if (inbox && inbox[0]) {
            return inbox[0]
        }
        return null
    }

    // properites for the plan features based on thec urren plan for the inbox. 
    getCurrentMaxInboxUsers(id: number) { 
        return this.getInbox(id)?.maxUsers
    }
    getMaxBroadcastSize(id: number) { 
        return this.getInbox(id)?.maxBroadcastSize
    }
    getMaxNumberOfChatflows(id: number) { 
        return this.getInbox(id)?.maxChatFlows
    }
    getMaxNumberOfTeams(id: number) { 
        return this.getInbox(id)?.maxTeams
    }
    getMaxNumberOfTemplates(id: number) { 
        return this.getInbox(id)?.maxTemplates
    }
    isAIReplyAllowed(id: number) { 
        return this.getInbox(id)?.isAIReplyAllowed
    }
    isAnalyticsAccessAllowed(id: number) { 
        return this.getInbox(id)?.isAnalyticsAccessAllowed
    }
    isBulkImportContactAllowed(id: number) { 
        return this.getInbox(id)?.isBulkImportContactAllowed
    }
    getInboxesWithAnalytics() {
        return this.inboxes.filter((x) => x.isAnalyticsAccessAllowed === true)       
    }

}

export class Inbox {
    inboxId: number
    name: string
    apiAccountId: number
    apiAccount: APIAccount
    folders: Folder[]
    planName: string
    isBulkImportContactAllowed: boolean
    isAnalyticsAccessAllowed: boolean
    isAIReplyAllowed: boolean
    maxTeams: number
    maxUsers: number
    maxChatFlows: number
    maxTemplates: number
    maxBroadcastSize: number

    constructor(id: number, name: string, apiAccountId: number, apiAccount: APIAccount, folders: Folder[], planName: string, isBulkImportContactAllowed: boolean, isAnalyticsAccessAllowed: boolean, isAIReplyAllowed: boolean, maxTeams: number, maxUsers: number, maxChatFlows: number, maxTemplates: number, maxBroadcastSize: number) {
        makeAutoObservable(this, { inboxId: false, name: false, apiAccountId: false, apiAccount: false, folders: false, planName: false, isBulkImportContactAllowed: false, isAnalyticsAccessAllowed: false, isAIReplyAllowed: false, maxTeams: false, maxUsers: false, maxChatFlows: false, maxTemplates: false, maxBroadcastSize: false })
        this.inboxId = id
        this.name = name
        this.apiAccount = apiAccount
        this.apiAccountId = apiAccountId
        this.folders = folders
        this.planName = planName
        this.isBulkImportContactAllowed = isBulkImportContactAllowed
        this.isAnalyticsAccessAllowed = isAnalyticsAccessAllowed
        this.isAIReplyAllowed = isAIReplyAllowed
        this.maxTeams = maxTeams
        this.maxUsers = maxUsers
        this.maxChatFlows = maxChatFlows
        this.maxTemplates = maxBroadcastSize
        this.maxBroadcastSize = maxBroadcastSize
    }
}

export class APIAccount {
    apiAccountId: number
    name: string
    apiAccountUUID: string
    
    constructor(id: number, name: string, apiAccountUUID: string) {
        makeAutoObservable(this, { apiAccountId: false, name: false, apiAccountUUID: false })
        this.apiAccountId = id
        this.name = name
        this.apiAccountUUID = apiAccountUUID
    }
}

export class Folder {
    folderId: number
    name: string
    isInbox: boolean
    isArchive: boolean
    isSpam: boolean
    inboxId: number 

    constructor(folderId: number, name: string, isInbox: boolean, isArchive: boolean, isSpam: boolean, inboxId: number) {
        makeAutoObservable(this, { folderId: false, name: false, isInbox:false, isArchive:false, isSpam: false, inboxId: false })
        this.folderId = folderId
        this.name = name
        this.isArchive = isArchive
        this.isInbox = isInbox
        this.isSpam = isSpam
        this.inboxId = inboxId
    }
}